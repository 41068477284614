.container-live-rate a {
    color: #0d75a2;
}

.container-live-rate a:hover {
    color: #31B5E3;
    text-decoration: none;
}

.container-live-rate .nav-tabs .nav-link {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-top-width: 2px;
    color: #0d75a2;
}

.container-live-rate .nav-tabs .nav-link:hover {
    background-color: #F0F8FC;
}

.container-live-rate .nav-tabs .nav-link.active:hover {
    background-color: #fff;
}

.container-live-rate .nav-tabs .nav-link.active {
    color: #000 !important;
    border-top-color: #F9AA24;
}

.container-live-rate .tab-content {
    padding: 15px 0;
    background: #fff;
}

.container-live-rate .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-live-rate {
    --bs-table-striped-bg: rgba(13, 117, 162, 0.02);
    --bs-table-hover-bg: rgba(13, 117, 162, 0.06);
}

.table-live-rate thead tr th {
    background-color: #E0EAF5;
    border-bottom-width: 0;
    font-size: 87.5%;
    line-height: 1.2;
}

.table-live-rate tbody tr td {
    border-bottom: 0;
    line-height: 1.2;
}

.table-live-rate .img-flag {
    width: 18px;
    margin-bottom: 2px;
    height: auto;
    display: inline-block;
    vertical-align: middle;
}

.table-live-rate .img-arrow {
    height: 14px;
    margin-bottom: 2px;
    width: auto;
    display: inline-block;
    vertical-align: middle;
}

.table-live-rate .bg-primary {
    background-color: #0D688F !important;
}

@media screen and (max-width:768px) {
    .table-live-rate .mt-1 {
        margin-top: 2px !important;
    }
    .table-live-rate .me-1 {
        margin-right: 2px !important;
    }
    .table-live-rate .mb-1 {
        margin-bottom: 2px !important;
    }
}

@media screen and (max-width:480px) {
    .container-live-rate {
        font-size: 87.5%;
    }
    .table-live-rate thead tr th {
        font-size: 81.25%;
    }
    .table-live-rate tbody tr td {
        font-size: 87.5%;
    }
}

@media (min-width: 1400px){
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1024px;
    }
}

@media (min-width: 1200px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1024px;
    }
}
